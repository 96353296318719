import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

const TutorialPopover = ({ tutorial }: TutorialPopoverProps) => {
  return (
    <div>
      <pre>{tutorial}</pre>
    </div>
  );
};

const TutorialPopoverType = {
  tutorial: PropTypes.string.isRequired,
};

type TutorialPopoverProps = InferProps<typeof TutorialPopoverType>;

export default TutorialPopover;
