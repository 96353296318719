import React from 'react';
import { Layout } from 'antd';
import './layout.scss';
import { useLocation } from 'react-router-dom';
import CustomHeader from './header';
// import CustomFooter from './footer';

const { Content } = Layout;

const NO_HEADER_FOOTER_PATH = ['/login'];

const CustomLayout = ({ children }: any) => {
  const location = useLocation();

  return (
    <Layout className="layout">
      {!NO_HEADER_FOOTER_PATH.includes(location.pathname) && <CustomHeader />}
      <Content className="layout-content">
        <div
          className={
            !NO_HEADER_FOOTER_PATH.includes(location.pathname)
              ? 'site-layout-content'
              : 'site-layout-content-no-padding'
          }
        >
          {children}
        </div>
      </Content>
      {/* {!NO_HEADER_FOOTER_PATH.includes(location.pathname) && <CustomFooter />}{' '} */}
    </Layout>
  );
};

export default CustomLayout;
