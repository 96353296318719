import {
  FETCH_PARAMETERS_ERROR,
  FETCH_PARAMETERS_SUCCESS,
  EDIT_PARAMETER_SUCCESS,
  EDIT_PARAMETER_ERROR,
} from '../actions/parametersAction';

const initialState = {
  parameters: [],
  error: null,
};

export default function processesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PARAMETERS_SUCCESS: {
      return {
        ...state,
        parameters: action.payload.parameters,
      };
    }
    case FETCH_PARAMETERS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case EDIT_PARAMETER_SUCCESS: {
      if (action.payload.parameter && action.payload.parameter.length > 1) {
        const parameters = [...state.parameters];
        const parameter = action.payload.parameter[1][0];

        if (parameter) {
          const index = parameters.findIndex(p => p.key === parameter.key);
          parameters[index] = parameter;
          return {
            ...state,
            parameters,
          };
        }
      }

      return state;
    }
    case EDIT_PARAMETER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
