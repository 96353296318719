// @ts-ignore
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { USER_AUTHENTICATION_SUCCESS, USER_AUTHENTICATION_ERROR, SIGN_OUT } from '../actions/userAction';

const initialState = {
  token: null,
  isAuth: false,
  firstName: null,
  lastName: null,
  email: null,
  loginError: null,
};

export default function collaboratorsReducer(state = initialState, action) {
  switch (action.type) {
    case USER_AUTHENTICATION_SUCCESS: {
      const { token } = action.payload;
      Cookies.set('jwt', token, { expires: 1, secure: true });
      const decodedToken = jwt_decode(token);

      return {
        ...state,
        token,
        isAuth: true,
        firstName: decodedToken.first_name,
        lastName: decodedToken.last_name,
        email: decodedToken.email,
      };
    }

    case USER_AUTHENTICATION_ERROR:
      return {
        ...state,
        token: null,
        isAuth: false,
        loginError: action.payload.error,
      };

    case SIGN_OUT:
      return {
        ...state,
        ...initialState,
      };
    default: {
      return state;
    }
  }
}
