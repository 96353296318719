import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { notification } from 'antd';

const Toastr: React.FC<ToastrProps> = ({ message, description, type, onClose }: ToastrProps) => {
  const renderSwitch = (toastrType: string, toastrMessage: string, toastrDescription: string) => {
    switch (toastrType) {
      case 'SUCCESS':
        return notification.success({
          message: toastrMessage,
          description: toastrDescription,
          placement: 'topRight',
          duration: 3,
          onClose,
        });
      case 'ERROR':
        return notification.error({
          message: toastrMessage,
          description: toastrDescription,
          placement: 'topRight',
          duration: 3,
          onClose,
        });
      case 'INFO':
        return notification.info({
          message: toastrMessage,
          description: toastrDescription,
          placement: 'topRight',
          duration: 3,
          onClose,
        });
      default:
        return null;
    }
  };

  useEffect(() => {
    renderSwitch(type, message, description);
  }, []);

  return null;
};

const ToastrPropTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['ERROR', 'INFO', 'SUCCESS']).isRequired,
  onClose: PropTypes.func.isRequired,
};
type ToastrProps = InferProps<typeof ToastrPropTypes>;

export default Toastr;
