import React, { Suspense } from 'react';
import { InferProps } from 'prop-types';
import { Spin, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Users = React.lazy(() => import('./users/users'));
const Threshold = React.lazy(() => import('./threshold/threshold'));
const ExportHistory = React.lazy(() => import('./exportHistory/exportHistory'));
const EmailTemplates = React.lazy(() => import('./emailTemplates/emailTemplates'));
const Tutorial = React.lazy(() => import('./tutorial/tutorial'));

const { TabPane } = Tabs;

const Administration: React.FC<AdministrationProps> = () => {
  return (
    <>
      <Tabs defaultActiveKey="1" type="card" centered>
        <TabPane
          tab={
            <span className="tabs_title">
              <FontAwesomeIcon icon="users-cog" />
              Gestion utilisateurs
            </span>
          }
          key="1"
        >
          <Suspense fallback={<Spin />}>
            <Users />
          </Suspense>
        </TabPane>
        <TabPane
          tab={
            <span className="tabs_title">
              <FontAwesomeIcon icon="history" />
              Exporter Historique
            </span>
          }
          key="2"
        >
          <Suspense fallback={<Spin />}>
            <ExportHistory />
          </Suspense>
        </TabPane>
        <TabPane
          tab={
            <span className="tabs_title">
              <FontAwesomeIcon icon="tachometer-alt" />
              Modifier seuil
            </span>
          }
          key="3"
        >
          <Suspense fallback={<Spin />}>
            <Threshold />
          </Suspense>
        </TabPane>
        <TabPane
          tab={
            <span className="tabs_title">
              <FontAwesomeIcon icon={['far', 'envelope']} />
              Template Mail
            </span>
          }
          key="4"
        >
          <Suspense fallback={<Spin />}>
            <EmailTemplates />
          </Suspense>
        </TabPane>
        <TabPane
          tab={
            <span className="tabs_title">
              <FontAwesomeIcon icon="info-circle" />
              Tutoriel
            </span>
          }
          key="5"
        >
          <Suspense fallback={<Spin />}>
            <Tutorial />
          </Suspense>
        </TabPane>
      </Tabs>
    </>
  );
};

const AdministrationPropTypes = {};
type AdministrationProps = InferProps<typeof AdministrationPropTypes>;

export default Administration;
