import { notification } from 'antd';
import Cookies from 'js-cookie';
import { store } from '../store';

const fetcher = (url: any, options?: any) => {
  function updateOptions(opts?: any) {
    const update = { ...opts };
    const token = Cookies.get('jwt');
    if (token) {
      update.headers = {
        ...update.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return update;
  }

  return fetch(url, updateOptions(options)).then(res => {
    if (res.status === 401) {
      store.dispatch({
        type: 'SIGN_OUT',
      });
      notification.warning({
        message: 'Session expiré',
        description: 'Votre session a expiré, merci de vous reconnectez.',
      });
    }
    return res;
  });
};

export default fetcher;
