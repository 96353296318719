import React, { useState } from 'react';
import 'moment/locale/fr';
import locale from 'antd/lib/locale/fr_FR';
import { ConfigProvider, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const EditableDateCell = ({ value, change, className }: any) => {
  const [pickerValue, setPickerValue] = useState(moment(value));
  const handleChange = (date: any) => {
    change(moment(date).format('MM/DD/YYYY'));
    setPickerValue(date);
  };
  return (
    <ConfigProvider locale={locale}>
      <DatePicker
        className={`datepicker ${className}`}
        format="DD/MM/YY"
        value={pickerValue}
        onChange={(date: any) => handleChange(date)}
      />
    </ConfigProvider>
  );
};

EditableDateCell.propType = {
  value: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  className: PropTypes.string,
};

EditableDateCell.defaultProps = {
  className: '',
};

export default EditableDateCell;
