import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthConsumer } from '../../../_providers/authProvider/auth.context';

const PrivateRoute = ({ children, ...rest }: any) => {
  return (
    <AuthConsumer>
      {({ isAuth }) => (
        <Route
          {...rest}
          render={({ location }: any) =>
            isAuth ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </AuthConsumer>
  );
};

export default PrivateRoute;
