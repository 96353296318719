import {
  FETCH_COLLABORATORS_SUCCESS,
  FETCH_COLLABORATORS_ERROR,
  EDIT_COLLABORATOR_SUCCESS,
  EDIT_COLLABORATOR_ERROR,
} from '../actions/collaboratorsAction';

const initialState = {
  collaborators: [],
  error: null,
};

export default function collaboratorsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COLLABORATORS_SUCCESS: {
      return {
        ...state,
        collaborators: action.payload.collaborators,
      };
    }
    case FETCH_COLLABORATORS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case EDIT_COLLABORATOR_SUCCESS: {
      const collaborators = [...state.collaborators];
      const { editedCollaborators } = action.payload;
      if (editedCollaborators) {
        editedCollaborators.forEach(ec => {
          const index = collaborators.findIndex(p => p.id === ec.id);
          collaborators[index] = ec;
        });
        return {
          ...state,
          collaborators,
        };
      }
      return state;
    }

    case EDIT_COLLABORATOR_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
