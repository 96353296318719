export const FETCH_PARAMETERS_SUCCESS = 'FETCH_PARAMETERS_SUCCESS';
export const FETCH_PARAMETERS_ERROR = 'FETCH_PARAMETERS_ERROR';
export const EDIT_PARAMETER_SUCCESS = 'EDIT_PARAMETER_SUCCESS';
export const EDIT_PARAMETER_ERROR = 'EDIT_PARAMETER_ERROR';

export const fetchParametersSuccess = parameters => ({
  type: FETCH_PARAMETERS_SUCCESS,
  payload: { parameters },
});

export const fetchParametersError = error => ({
  type: FETCH_PARAMETERS_ERROR,
  payload: { error },
});

export const editParameterSuccess = parameter => ({
  type: EDIT_PARAMETER_SUCCESS,
  payload: { parameter },
});

export const editParameterError = error => ({
  type: EDIT_PARAMETER_ERROR,
  payload: { error },
});
