export const FETCH_COLLABORATORS_SUCCESS = 'FETCH_COLLABORATORS_SUCCESS';
export const FETCH_COLLABORATORS_ERROR = 'FETCH_COLLABORATORS_ERROR';
export const EDIT_COLLABORATOR_SUCCESS = 'EDIT_COLLABORATOR_SUCCESS';
export const EDIT_COLLABORATOR_ERROR = 'EDIT_COLLABORATOR_ERROR';

export const fetchCollaboratorsSuccess = collaborators => ({
  type: FETCH_COLLABORATORS_SUCCESS,
  payload: { collaborators },
});

export const fetchCollaboratorsError = error => ({
  type: FETCH_COLLABORATORS_ERROR,
  payload: { error },
});

export const editCollaboratorsSuccess = editedCollaborators => ({
  type: EDIT_COLLABORATOR_SUCCESS,
  payload: { editedCollaborators },
});

export const editCollaboratorsError = error => ({
  type: EDIT_COLLABORATOR_ERROR,
  payload: { error },
});
