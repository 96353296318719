export const USER_AUTHENTICATION_SUCCESS = 'USER_AUTHENTICATION_SUCCESS';
export const USER_AUTHENTICATION_ERROR = 'USER_AUTHENTICATION_ERROR';
export const SIGN_OUT = 'SIGN_OUT';

export const userAuthenticationSuccess = token => ({
  type: USER_AUTHENTICATION_SUCCESS,
  payload: { token },
});

export const userAuthenticationError = error => ({
  type: USER_AUTHENTICATION_ERROR,
  payload: { error },
});

export const signOut = () => ({
  type: SIGN_OUT,
});
