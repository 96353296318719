export const FETCH_PROCESSES_SUCCESS = 'FETCH_PROCESSES_SUCCESS';
export const FETCH_PROCESSES_ERROR = 'FETCH_PROCESSES_ERROR';
export const EDIT_PROCESS_SUCCESS = 'EDIT_PROCESS_SUCCESS';
export const EDIT_PROCESS_ERROR = 'EDIT_PROCESS_ERROR';

export const fetchProcessesSuccess = processes => ({
  type: FETCH_PROCESSES_SUCCESS,
  payload: { processes },
});

export const fetchProcessesError = error => ({
  type: FETCH_PROCESSES_ERROR,
  payload: { error },
});

export const editProcessSuccess = process => ({
  type: EDIT_PROCESS_SUCCESS,
  payload: { process },
});

export const editProcessError = error => ({
  type: EDIT_PROCESS_ERROR,
  payload: { error },
});
