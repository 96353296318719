import moment from 'moment';

const formatTableData = (value: any = '', column: any, option?: any) => {
  switch (column) {
    case 'is_in_process':
      if (value) {
        return 'check';
      }
      return 'times';
    case 'step':
      return value && value.step ? `${value.step}` : '';
    case 'mailSendDates':
      if (option === 'date') {
        return value && value[value.length - 1] ? moment(value[value.length - 1]).format('DD/MM/YY') : 'Aucun';
      }
      if (option === 'week') {
        return value && value[value.length - 1] ? `W ${moment(value[value.length - 1]).week()}` : null;
      }
      return 'Aucun';
    default:
      return value;
  }
};

export default formatTableData;
