/* eslint-disable */
import React, { useEffect, useContext } from 'react';
import { Row, Col, Button } from 'antd';
import { authContext } from '../../_providers/authProvider/auth.context';

import './login.scss';

const Login = () => {
  const ctx = useContext(authContext);
  useEffect(() => {
    console.log('front --- useEffect/login : before getSSOConnectedUser()');
    ctx.getSSOConnectedUser();
  }, []);

  return (
    <Row className="container">
      <Col span={8} className="login_panel">
        <img src="/images/logo-texte_1.svg" alt="MPP logo" className="login_logo" />
        <div>
          <h2>Bienvenue!</h2>
          <h3>Connectez-vous pour accéder à l&apos;outil de gestion de profil des collaborateurs</h3>
        </div>
        <div className="panel_bottom">
          <h3>Cliquez sur le lien ci-dessous pour être redirigé vers la page de connexion de votre organisation.</h3>
          <Button type="primary" className="btn" onClick={ctx.login}>
            Atos SSO Portal
          </Button>
        </div>
      </Col>
      <Col span={16}>
        <img src="/images/Mpplogin.jpg" alt="MPP login" className="login_img" />
      </Col>
    </Row>
  );
};

export default Login;
