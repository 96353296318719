import React, { useState } from 'react';
import Toastr from './toastr';

const Ctx = React.createContext(null as null | any);

// Provider
// ==============================
export const ToastProvider = ({ children }: any) => {
  const [toasts, setToasts] = useState([] as any[]);

  const addToastr = (message: string, description: string, type: string) => {
    const id = toasts.length + 1;
    const toast = { message, description, type, id };
    setToasts([...toasts, toast]);
  };

  const removeToastr = (id: number) => {
    const newToasts = toasts.filter((t: any) => t.id !== id);
    setToasts(newToasts);
  };

  // avoid creating a new fn on every render
  const onDismiss = (id: number) => () => removeToastr(id);

  return (
    <Ctx.Provider value={{ addToastr, removeToastr }}>
      {children}
      {toasts.map((t: any) => (
        <Toastr
          key={t.id}
          message={t.message}
          description={t.description}
          type={t.type}
          onClose={() => onDismiss(t.id)}
        />
      ))}
    </Ctx.Provider>
  );
};

// Consumer
// ==============================

export const useToasts = () => React.useContext(Ctx);
