import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/index';
import PrivateRoute from './_components/core/routing/privateRoute';
import CustomLayout from './_components/core/template/layout';
import Login from './_components/login/login';
import Administration from './_components/administration/administration';
import Help from './_components/core/template/help';
import './_utils/fontawesome';
import './App.less';
import { ToastProvider } from './_components/shared/toastr/toast.manager';
import Home from './_components/core/template/home/home';
import AuthProvider from './_providers/authProvider/auth.provider';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastProvider>
          <Router>
            <AuthProvider>
              <CustomLayout>
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <PrivateRoute exact path="/">
                    <Home />
                  </PrivateRoute>
                  <PrivateRoute path="/admin">
                    <Administration />
                  </PrivateRoute>
                  <PrivateRoute path="/help">
                    <Help />
                  </PrivateRoute>
                  <PrivateRoute path="*">
                    <Home />
                  </PrivateRoute>
                </Switch>
              </CustomLayout>
            </AuthProvider>
          </Router>
        </ToastProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
