import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spin } from 'antd';

import './help.scss';

const Help = () => {
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument } = usePdf({
    file: './documentationUtilisateur.pdf',
    page,
    canvasRef,
  });

  return (
    <div className="pdf_viewer">
      {pdfDocument && (
        <nav className="nav">
          <Button
            disabled={page === 1}
            size="large"
            onClick={() => setPage(page - 1)}
            icon={<FontAwesomeIcon icon="chevron-left" />}
          />
        </nav>
      )}
      <div className="canvas">{!pdfDocument ? <Spin /> : <canvas ref={canvasRef} />}</div>
      {pdfDocument && (
        <nav className="nav">
          <Button
            className="nav-btn"
            size="large"
            disabled={page === pdfDocument.numPages}
            onClick={() => setPage(page + 1)}
            icon={<FontAwesomeIcon icon="chevron-right" />}
          />
        </nav>
      )}
    </div>
  );
};

export default Help;
