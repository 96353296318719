/* eslint-disable */
import React, { useEffect } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AuthService from '../../_services/auth.service';
import { authContext } from './auth.context';
import { userAuthenticationError, userAuthenticationSuccess, signOut } from '../../store/actions/userAction';

const AuthProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state: any) => state.user.isAuth);
  const loginError = useSelector((state: any) => state.user.loginError);
  const history = useHistory();
  const location = useLocation();
  const from: any = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    if (isAuth && location.pathname === '/login') {
      history.replace('/');
    }
  });

  const login = () => {
    console.log('Front --- Login Function');
    dispatch(userAuthenticationError(''));
    AuthService.getSSOLink()
      .then(json => {
        console.log('Front --- Login: returned url');
        console.log(json.url);
        window.location = json.url;
      })
      .catch(err => {
        const errorMessage = `An error has occurred, please try again later. ${err}`;
        dispatch(userAuthenticationError(errorMessage));
      });
  };

  const getSSOConnectedUser = () => {
    console.log('Front --- getSSOConnectedUser Function');
    AuthService.getSSOConnectedUser()
      .then(json => {
        console.log('Front --- getSSOConnectedUser: returned token:');
        console.log(json.token);
        if (json.token) {
          dispatch(userAuthenticationSuccess(json.token));
          history.push(from.pathname);
        }
      })
      .catch(err => {
        const errorMessage = `An error has occurred, please try again later. ${err}`;
        dispatch(userAuthenticationError(errorMessage));
      });
  };

  const logout = () => {
    dispatch(signOut());
  };

  return (
    <authContext.Provider value={{ isAuth, loginError, login, logout, getSSOConnectedUser }}>
      {children}
    </authContext.Provider>
  );
};

export default withRouter(AuthProvider);
