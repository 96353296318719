import { fetchProcessesSuccess, fetchProcessesError } from '../store/actions/processesAction';
import fetcher from '../_utils/fetcher';

class ProcessesService {
  static fetchProcesses(dispatch: any) {
    return fetcher(`${process.env.REACT_APP_BACK_URL}/processes`)
      .then(res => res.json())
      .then(json => dispatch(fetchProcessesSuccess(json)))
      .catch(err => dispatch(fetchProcessesError(err)));
  }

  static editProcesses(editedProcess: any) {
    return fetcher(`${process.env.REACT_APP_BACK_URL}/processes`, {
      method: 'PUT',
      body: JSON.stringify(editedProcess),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(json => json)
      .catch(err => err);
  }

  static getRequiredFields(processes: any) {
    if (!processes || processes.length === 0) return [];
    const requiredFields = [] as string[];
    processes.forEach((p: any) => {
      if (p.required_fields && p.required_fields.length > 0) {
        p.required_fields.forEach((r: any) => {
          if (!requiredFields.includes(r)) {
            requiredFields.push(r);
          }
        });
      }
    });
    return requiredFields;
  }
}

export default ProcessesService;
