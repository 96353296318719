// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
  faTimes,
  faUserPlus,
  faEdit,
  faTrash,
  faChevronDown,
  faUsersCog,
  faHistory,
  faTachometerAlt,
  faCheck,
  faUserCheck,
  faChevronRight,
  faChevronLeft,
  faExclamationTriangle,
  faTimesCircle,
  faInfoCircle,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { faUser, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';

library.add(
  faUpload,
  faTimesCircle,
  faExclamationTriangle,
  faChevronDown,
  faCheck,
  faTimes,
  faUser,
  faUserPlus,
  faUsersCog,
  faUserCheck,
  faEdit,
  faTrash,
  faChevronDown,
  faHistory,
  faTachometerAlt,
  faEnvelope,
  faChevronRight,
  faChevronLeft,
  faInfoCircle
);
