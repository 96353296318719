import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getInitials from '../../../_utils/getInitials';
import { authContext } from '../../../_providers/authProvider/auth.context';

import './header.scss';

const { Header } = Layout;

const CustomHeader = () => {
  const user = useSelector((state: any) => state.user);
  const ctx = useContext(authContext);
  const SSOLogout = () => {
    fetch(`${process.env.REACT_APP_BACK_URL}/authentication/sso/logout`)
      .then(res => {
        if (res.status === 200) {
          ctx.logout();
        }
      })
      .catch(err => err);
  };

  const menu = (
    <Menu>
      <Menu.Item key="sub-1">
        <Link to="/help">Aide</Link>
      </Menu.Item>
      <Menu.Item key="sub-2" onClick={SSOLogout}>
        Déconnexion
      </Menu.Item>
    </Menu>
  );

  return (
    <Header>
      <Link to="/">
        <div className="logo_div">
          <img src="/images/logo-texte_blanc.svg" alt="Logo MPP" />
        </div>
      </Link>
      {user.isAuth && (
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            <Link to="/">Tableau général</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/admin">Paramètrage</Link>
          </Menu.Item>

          <Menu.Item key="3" className="menu-item-right">
            <Dropdown overlay={menu}>
              <div className="item-right-div">
                <div className="initial_container">
                  <img src="/images/losange-mpp.svg" alt="Logo MPP" className="menu-item-right-img" />
                  <div className="initial_centered">{getInitials(user.firstName, user.lastName)}</div>
                </div>

                <div className="fullname_container">
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                </div>
                <FontAwesomeIcon icon="chevron-down" className="menu-item-right-svg" />
              </div>
            </Dropdown>
          </Menu.Item>
        </Menu>
      )}
    </Header>
  );
};

export default CustomHeader;
