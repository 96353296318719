import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import debounce from 'lodash.debounce';
import './cell.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditableTextCell = ({ text, change, className, schema, inputType }: any) => {
  const [value, setValue] = useState(text);

  const handler = useCallback(
    debounce((val: any) => change(val), 5000),
    []
  );

  const formik = useFormik({
    initialValues: {
      input: value,
    },
    validationSchema: schema,
    onSubmit: values => {
      handler(values.input);
    },
  });

  const handleChange = (e: any) => {
    const val = e.target.value;
    formik.values.input = val;
    setValue(val);
    formik.submitForm();
  };

  return (
    <div>
      <Input
        id="input"
        type={inputType}
        {...formik.getFieldProps('input')}
        onChange={e => handleChange(e)}
        className={`input ${className}`}
      />
      {formik.touched.input && formik.errors.input && <div className="error">{formik.errors.input}</div>}
    </div>
  );
};

EditableTextCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.any.isRequired,
  change: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
  className: PropTypes.string,
  inputType: PropTypes.string,
};

EditableTextCell.defaultProps = {
  className: '',
  inputType: 'text',
  schema: Yup.object({
    input: Yup.string(),
  }),
};

export default EditableTextCell;
