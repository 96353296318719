const getLongest = (data: any, key?: any, additionalContent = '', label = '') => {
  return data.reduce((a: any, b: any) => {
    let aCopy = a;
    let bCopy = b;
    if (typeof aCopy === 'object') {
      aCopy = `${additionalContent}${a[key]}`;
    }
    if (typeof bCopy === 'object') {
      bCopy = `${additionalContent}${b[key]}`;
    }
    const longestContent = aCopy.length > bCopy.length ? aCopy : bCopy;
    return longestContent.length > label.length ? longestContent : label;
  });
};
export default getLongest;
