import { combineReducers, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';

import processes from './reducers/processes';
import collaborators from './reducers/collaborators';
import parameters from './reducers/parameters';
import user from './reducers/user';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['collaborators'],
};

const rootReducer = combineReducers({
  processes,
  collaborators,
  parameters,
  user,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
const persistor = persistStore(store);

export { store, persistor };
