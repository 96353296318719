import { fetchCollaboratorsError, fetchCollaboratorsSuccess } from '../store/actions/collaboratorsAction';
import fetcher from '../_utils/fetcher';

class CollaboratorsService {
  static updateCollaborators(collaborators: any) {
    return fetcher(`${process.env.REACT_APP_BACK_URL}/collaborators`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(collaborators),
    })
      .then(res => res.json())
      .then(json => json)
      .catch(err => err);
  }

  static fetchCollaborators(dispatch: any) {
    return fetcher(`${process.env.REACT_APP_BACK_URL}/collaborators`)
      .then(res => res.json())
      .then(json => dispatch(fetchCollaboratorsSuccess(json)))
      .catch(err => dispatch(fetchCollaboratorsError(err)));
  }

  static exportCollaborators(collaborators: any) {
    return fetcher(`${process.env.REACT_APP_BACK_URL}/collaborators/export`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(collaborators),
    })
      .then(res => res.blob())
      .then(blob => blob)
      .catch(err => err);
  }

  static sendMails(ids: any) {
    return fetcher(`${process.env.REACT_APP_BACK_URL}/collaborators/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ids),
    })
      .then(response => response)
      .catch(err => err);
  }
}

export default CollaboratorsService;
