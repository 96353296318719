import React from 'react';

interface IAuthContext {
  isAuth: boolean;
  loginError: string;
  login: () => void;
  logout: () => void;
  getSSOConnectedUser: () => void;
}

const authContext = React.createContext<IAuthContext>({
  isAuth: false,
  loginError: '',
  login: () => {},
  logout: () => {},
  getSSOConnectedUser: () => {},
});

const AuthConsumer = authContext.Consumer;

export { authContext, AuthConsumer };
