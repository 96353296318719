import {
  FETCH_PROCESSES_SUCCESS,
  FETCH_PROCESSES_ERROR,
  EDIT_PROCESS_SUCCESS,
  EDIT_PROCESS_ERROR,
} from '../actions/processesAction';

const initialState = {
  processes: [],
  error: null,
};

export default function processesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROCESSES_SUCCESS: {
      return {
        ...state,
        processes: action.payload.processes,
      };
    }
    case FETCH_PROCESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case EDIT_PROCESS_SUCCESS: {
      if (action.payload.process && action.payload.process.length > 1) {
        const processes = [...state.processes];
        const process = action.payload.process[1][0];

        if (process) {
          const index = processes.findIndex(p => p.id === process.id);
          processes[index] = process;
          return {
            ...state,
            processes,
          };
        }
      }

      return state;
    }
    case EDIT_PROCESS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
