import { fetchParametersError, fetchParametersSuccess } from '../store/actions/parametersAction';
import fetcher from '../_utils/fetcher';

class ParametersService {
  static fetchParameters(dispatch: any) {
    return fetcher(`${process.env.REACT_APP_BACK_URL}/parameters`)
      .then(res => res.json())
      .then(json => dispatch(fetchParametersSuccess(json)))
      .catch(err => dispatch(fetchParametersError(err)));
  }

  static editParameter(parameter: any) {
    return fetcher(`${process.env.REACT_APP_BACK_URL}/parameters`, {
      method: 'PUT',
      body: JSON.stringify(parameter),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(json => json)
      .catch(err => err);
  }

  static getLastImportDate(parameters: any) {
    if (!parameters || !Array.isArray(parameters)) return '';
    const lastImport = parameters.find((p: any) => p.key === 'last_import');
    if (!lastImport || lastImport.value.length === 0) return '';
    return lastImport.value[0];
  }
}

export default ParametersService;
